import { BaseFunctions } from "./js/basefunctions.js";
import { BurgerNavi } from "./js/burgernavi.js";
import { ImgSlides } from "./js/imgslides.js";

const breakpointMedium = 768;
const breakpointLarge = 1100;

document.addEventListener("DOMContentLoaded", function (event) {
    const basefunctions = new BaseFunctions(breakpointMedium);
    basefunctions.init();

    const burgernavi = new BurgerNavi();
    burgernavi.init();  

    if(document.querySelectorAll('.img-swiper-container').length > 0) {
        const imgslides = new ImgSlides();
        imgslides.init();
    }
});