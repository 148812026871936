import Swiper, { Autoplay, EffectFade } from 'swiper';


export class ImgSlides {
    constructor() {
        this.speed = 700;
    }

    init() {
        const self = this;

        if (document.querySelectorAll('.img-swiper-container').length > 0) {
            Array.from(document.querySelectorAll('.img-swiper-container')).forEach(function (s) {
                var slideshow = new Swiper(s, {
                    modules: [Autoplay, EffectFade],
                    slidesPerView: 1,
                    loop: true,
                    speed: 800,
                    grabCursor: true,
                    slidesPerView: 1,
                    effect: "fade",
                    fadeEffect: { crossFade: true },
                    autoplay: {
                        delay: 3000,
                        //disableOnInteraction: true
                    },
                });
            });
        }
    }
}